import React from 'react';
import migracionLogo from '../../images/v3/migracionLogo.png'
import MyConstant from '../../config/MyConstant'
import { Link } from 'react-router-dom';
import { userTrack } from '../../config/MyFunctions';
import Footer from '../../common/Footer3';
import appleIcon from '../../images/AppleIconLink.png';
import androidIcon from '../../images/android.png';
import UmedMeLite from '../../images/umedmeLink.png';
import web from '../../images/web.png';
import updateImage from '../../images/LightBg2.gif'

class MigrationApps extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            holdImage: true,
            oneTimeSet: false,
            mobileMenu: false
        }
    }

    componentDidMount() {
        userTrack("Migration Apps Page", "Landing");
    }

    setMobileMenu(mobileMenu) {
        this.setState({
            mobileMenu
        })
    }

    render() {
        let { mobileMenu } = this.state;
        return <>
            <div className="umedme-version-3 migration">
                <div className="bg">
                    <div className='header' style={{ background: "#aec1c2" }}>
                        <div className='logo-container'>
                            <Link to={MyConstant.keyList.projectUrl + "/migration"}>
                                <img src={migracionLogo} className="logo" />
                            </Link>
                        </div>
                        <div className='update-logo w-100 justify-content-center'>
                            <a href={"https://teqbahn.com/mid-year-update-2024"}>
                                <img alt="UmedMe" className='header-logo' src={updateImage} />
                            </a>
                        </div>
                        <div className='menu-link'>
                            <Link to={MyConstant.keyList.projectUrl + "/"}>
                                Home
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-apps"}>
                                Apps
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-media"}>
                                Media
                            </Link>
                            <Link to={MyConstant.keyList.projectUrl + "/migration-overview"}>
                                Overview
                            </Link>
                        </div>
                        <div className='mobile-link' onClick={() => {
                            this.setMobileMenu(true)
                        }}>
                            <i className="fa fa-bars"></i>
                        </div>
                        {
                            mobileMenu && <div className='mobile-menu'>
                                <div className='header d-flex'>
                                    <div className='main-logo'>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration"}>
                                            <img alt="UmedMe" className='logo' src={migracionLogo} />
                                        </Link>
                                    </div>
                                    <div className='menu-close' onClick={() => {
                                        this.setMobileMenu(false)
                                    }}>
                                        <i className='fa fa-times' />
                                    </div>
                                </div>
                                <div className='mobile-menu-link'>
                                    <div className='w-100 d-flex justify-content-center align-items-center mb-5'>
                                        <a href={"https://teqbahn.com/mid-year-update-2024"}>
                                            <img alt="UmedMe" className='header-logo' src={updateImage} />
                                        </a>
                                    </div>
                                    <div className='d-flex flex-column justify-content-center align-items-center mt-3'>
                                        <Link to={MyConstant.keyList.projectUrl + "/"}>
                                            Home
                                        </Link>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration-apps"}>
                                            Apps
                                        </Link>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration-media"}>
                                            Media
                                        </Link>
                                        <Link to={MyConstant.keyList.projectUrl + "/migration-overview"}>
                                            Overview
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='bg-setup'></div>
                </div>
                <div className='apps-download text-center pb-5' style={{ paddingTop: 125 }}>
                    Descargue la Aplicación o Regístrese en la Aplicación Web. <br />
                    La aplicación web no requiere correo electrónico. <br />
                    Puede registrarse solo con su nombre y el nombre de su país.<br />
                    ¡Nada más!<br />
                    Guarde de forma segura sus documentos de identidad y administre su atención médica. <br />
                    Nadie puede ver su información. ¡Ni siquiera nosotros!
                </div>

                <div className='download-box py-5' id={"download"} style={{ backgroundColor: '#e8f2f3' }}>
                    <div className='dowload-image d-flex'>
                        <div className='img'>
                            <a href='https://umed.me/app/' target='_blank' onClick={() => {
                                userTrack("Migration Apps Page", "Application Web Click");
                            }}>
                                <img src={web} alt={"Umedme"} />
                                <div>Aplicación Web</div>
                            </a>
                        </div>
                        <div className='img'>
                            <a href='https://play.google.com/store/apps/details?id=com.teqbahn.umedme' target='_blank' onClick={() => {
                                userTrack("Migration Apps Page", "Android Click");
                            }}>
                                <img src={androidIcon} alt={"Umedme"} />
                                <div>Androide</div>
                            </a>
                        </div>
                        <div className='img'>
                            <a href='https://apps.apple.com/in/app/umedme/id1631817085' target='_blank' onClick={() => {
                                userTrack("Migration Apps Page", "iPhone Click");
                            }}>
                                <img src={appleIcon} alt={"Umedme"} />
                                <div>iPhone</div>
                            </a>
                        </div>
                        <div className='img'>
                            <a href='https://umed.me/lite/' target='_blank' onClick={() => {
                                userTrack("Migration Apps Page", "Lite Web Click");
                            }}>
                                <img src={UmedMeLite} alt={"Umedme"} />
                                <div>Umed.me lite</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    }
}
export default MigrationApps